<template>
    <div class="container" :class="size">
        <iframe :width="widths[size]" :height="height[size]" class="map-iframe" :src="url" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </div>
</template>
<script>

export default {
    props:{
        location:{
            type: String
        },
        size: String
    },
    data(){
        return {
            widths:{
                sm: 250 - 16,
                lg: 600 - 16
            },
            height:{
                sm: 250 - 16,
                lg: 250 - 16
            }
        }
    },
    computed:{
        url(){
            if(this.location == "fr"){
                return "https://maps.google.com/maps?q=Ermitage%20des%20Frasses&t=&z=9&ie=UTF8&iwloc=&output=embed"
            }
            return "https://maps.google.com/maps?q=The%20Orchard%20at%20Sunshine%20Hill%202178%20S%20Scheuber%20Rd%2C%20Chehalis%2C%20Washington%2098532&t=&z=7&ie=UTF8&iwloc=&output=embed"
        }
    }
}
</script>
<style scoped>

.container{
    border: solid 8px white;
    margin: 1em;
    box-sizing: content-box;
}

.container.lg{
    max-width: calc(600px - 16px);
    max-height: calc(250px - 16px);
    margin: auto;
}

.container.sm{
    max-width: calc(250px - 16px);
    max-height: calc(250px - 16px);
    margin: auto;
}
.map-iframe{
}
</style>