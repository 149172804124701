<template>
  <div class="column container">
    <a class="cta-button" :href="url" target="_blank">
      <slot />
    </a>
  </div>
</template>
<script>
export default {
  props: {
    url: String
  },
  computed: {
  }

}
</script>
<style scoped>
.container {
  display: flex;
}

a.cta-button {
  margin: 5em auto 3em;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  border: solid 2px white;
  min-width: 200px;
  padding: 1em 2em;

  font-family: "Amatic SC";
  font-size: 3.5em;
  cursor: pointer;
  letter-spacing: 0.03em;
  border-radius: 10em;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 65%);
  transition: all 120ms;
  text-decoration: none;
}

a.cta-button:hover {
  padding: 1em 2.2em;
  background: rgba(255, 255, 255, 0.1);
}
</style>