<template>
  <div class="main" ref="main">
    <div class="bg-container">
      <div class="green-bg"></div>
    </div>
    <div class="big-fixed">
      <div class="header-mask"></div>
      <TitleHeader :lang="lang" :scrollV="scrollV">{{ displayDate }}</TitleHeader>
    </div>
    <div class="one-page"></div>
    <div class="content" v-if="invit">
      <ContentPage :scrollV="scrollV">
        <template v-if="invit == '2fr'">
          <span class="mali">
            Entourés de nos familles, nous sommes heureux
            de vous inviter à nos fêtes de mariage :
          </span>
          <br />
          <strong>
            en
            <span class="color">France</span>, le
            <span class="color">samedi 6&nbsp;août&nbsp;2022</span>
          </strong>
          <br />
          <span class="mali">à l’Ermitage des Frasses (Grande Rivière, Jura)</span>
          <br />
          <strong>&</strong>
          <br />
          <strong>
            aux
            <span class="color">Etats-Unis</span>, le
            <span class="color">dimanche 21&nbsp;août&nbsp;2022</span>
          </strong>
          <br />
          <span class="mali">à l’Orchard at Sunshine Hill à Chehalis, WA.</span>
        </template>
        <template v-if="invit == '1fr'">
          <span
            class="mali"
          >Entourés de nos familles, nous sommes heureux de vous inviter à fêter notre mariage</span>
          <br />
          <strong>le samedi 6&nbsp;août&nbsp;2022</strong>
          <br />
          <span class="mali">à l’Ermitage des Frasses (Grande Rivière, Jura) dans le Jura à 15h30.</span>
        </template>
        <template v-if="invit == '1us'">
          <span
            class="mali"
          >We cordially invite you to celebrate our marriage with us and our families:</span>
          <br />
          <strong>on Sunday, August&nbsp;21st&nbsp;2022</strong>
          <br />
          <span class="mali">at 2:00 pm at the Orchard at Sunshine Hill in Chehalis, WA.</span>
        </template>
        <template v-if="invit == '2us'">
          <span
            class="mali"
          >We cordially invite you to celebrate our marriage with us and our families:</span>
          <br />
          <strong>
            in
            <span class="color">France</span> on
            <span class="color">Saturday, August&nbsp;6th&nbsp;2022</span>
          </strong>
          <br />
          <span class="mali">at the Ermitage des Frasses (Grande Rivière, Jura)</span>
          <br />
          <strong>&</strong>
          <br />
          <strong>
            in the
            <span class="color">USA</span> on
            <span class="color">August&nbsp;21st&nbsp;2022</span>
          </strong>
          <br />
          <span class="mali">at the Orchard at Sunshine Hill in Chehalis, WA.</span>
        </template>
        <template v-if="invit == 'frp'">
          <span
            class="mali"
          >Entourés de nos familles, nous sommes heureux de vous inviter à fêter notre mariage</span>
          <br />
          <strong>le samedi 6&nbsp;août&nbsp;2022</strong>
          <br />
          <span class="mali">à l’Ermitage des Frasses (Grande Rivière, Jura) dans le Jura à 15h30.</span>
          <br />
          <br />
          <span class="mali bold">La célébration laïque aura lieu à 15h30.</span>
          <br />
          <br />
          <span class="mali">
            Nous serons heureux de poursuivre la journée en
            <br />votre compagnie pour
            <span class="bold">un vin d’honneur.</span>
          </span>
        </template>
      </ContentPage>
      <Parents v-if="invit != '1us' && invit != '2us'" />
      <Program :invit="invit" v-if="invit && invit != 'frp'" />

      <CTAButton :url="formUrl">
        <template v-if="lang == 'fr'">
          <i class="fas fa-hand-point-right hand-animated"></i>
          RSVP par ici !
          <div class="cta-subtext">avant le 6 juin</div>
        </template>
        <template v-else>
          <i class="fas fa-hand-point-right hand-animated"></i>
          RSVP here!
          <div class="cta-subtext">before 6th june</div>
        </template>
      </CTAButton>

      <template v-if="invit == '1fr' || invit == 'frp'">
        <h2 class="white">Le lieu</h2>
        <Venues country="fr" size="lg" lang="fr" />
      </template>

      <template v-else-if="invit == '2fr'">
        <h2 class="white">Les lieux</h2>
        <div class="country-sep column">
          <img class="img-fr" alt="flag-fr" />
          <h3 class="white">En France :</h3>
        </div>
        <Venues country="fr" size="sm" lang="fr" />
        <div class="country-sep column">
          <img class="img-us" alt="flag-us" />
          <h3 class="white">Aux Etats-Unis :</h3>
        </div>
        <Venues country="us" size="sm" lang="fr" />
      </template>

      <template v-else-if="invit == '2us'">
        <h2 class="white">Venues</h2>
        <div class="country-sep column">
          <img class="img-fr" alt="flag-fr" />
          <h3 class="white">In France :</h3>
        </div>
        <Venues country="fr" size="sm" lang="us" />
        <div class="country-sep column">
          <img class="img-us" alt="flag-us" />
          <h3 class="white">In the United-States :</h3>
        </div>
        <Venues country="us" size="sm" lang="us" />
      </template>

      <template v-else-if="invit == '1us'">
        <h2 class="white">Venue</h2>
        <Venues country="us" size="lg" lang="us" />
      </template>

      <ContactBox v-if="invit == '1fr'">
        <span class="mali">
          Afin de pouvoir profiter de la soirée sans soucis,
          nous offrons des possibilités de rester
          sur place jusqu’au lendemain.
        </span>
        <br />
        <span class="mali">
          <a
            href="mailto:blandine.caule@gmail.com"
          >Pour plus d’information, merci de nous contacter !</a>
        </span>
      </ContactBox>
      <ContactBox v-else-if="invit == '2fr'">
        <span class="mali">
          Pour plus d'information sur les possibilités de logements en France et aux Etats-Unis.
          <a
            href="mailto:clementine.caule@gmail.com"
          >Merci de nous contacter !</a>
        </span>
      </ContactBox>
      <ContactBox v-if="invit == '1us' || invit == '2us'">
        <span class="mali">
          We are coordinating with a hotel for rooms and a shuttle service.
          <a
            href="mailto:clementine.caule@gmail.com"
          >Please contact us if you are interested!</a>
        </span>
      </ContactBox>

      <!-- <ContentPage v-if="lang == 'fr'">
        <h2>Sécurité sanitaire</h2>
        <p class="mali">Chers invités,</p>
        <p class="mali">
          Nous avons hâte de fêter notre mariage avec vous, et nous
          avons aussi à coeur de vous garder en bonne santé et
          d’éviter d’engendrer plus de difficultés liées à la COVID.
        </p>
        <p class="mali">
          Si vous souhaitez vous joindre à nous, nous vous
          demanderons de bien vouloir nous présenter une preuve de
          vaccination ou un test COVID récent (pass sanitaire).
        </p>
        <p class="mali">
          Si des changements d’organisation s’avèrent être
          nécessaires, vous en serez informés par email et sur ce site
          internet.
        </p>
        <h4>Merci pour votre compréhension !</h4>
      </ContentPage>-->

      <!-- <ContentPage v-else-if="lang == 'us'">
        <h2 class="amatic">COVID Wedding Safety Plan</h2>
        <p class="mali">Dear family & friends,</p>
        <p class="mali">
          We are hopeful that we will be able to celebrate our
          wedding in August 2022, but we also want to ensure that
          everyone attending our wedding feels safe.
        </p>
        <p class="mali">
          As of now, our plan is to host both our ceremony and
          reception outdoors. We will require that everyone
          attending comes with a proof of vaccination or with a
          recent COVID test.
        </p>
        <p class="mali">
          You will find the latest info on our COVID Safety Plan on
          this website. We’ll keep this updated regularly, so please
          check it out in the weeks and days prior to our wedding in
          case there are any changes to our plan. We will also send
          out an email a few days before our wedding to confirm any
          major changes.
        </p>
        <h4>Thank you for understanding!</h4>
      </ContentPage>-->

      <ContentPage v-if="lang == 'fr'">
        <span class="mali">
          Merci de confirmer votre
          présence avant
          <span class="bold">le 6 juin 2022.</span>
        </span>
        <p></p>
        <span class="mali">
          Pour cela, utiliser
          <a :href="formUrl">ce formulaire.</a>
        </span>
        <p></p>
        <span class="mali small">
          En cas de problème, merci de
          <a href="mailto:clementine.caule@gmail.com">nous contacter.</a>
        </span>
      </ContentPage>

      <ContentPage v-else-if="lang == 'us'">
        <span class="mali">
          Please respond by
          <span class="bold">
            June 6
            <sup>th</sup> 2022
          </span>
          using
          <a :href="formUrl" target="_blank">this online form.</a>
        </span>
        <p></p>
        <span class="mali small">
          If you need help, please
          <a href="mailto:clementine.caule@gmail.com">contact us.</a>
        </span>
      </ContentPage>

      <PhotoCard :lang="lang"></PhotoCard>

      <ContactBox v-if="invit == '1us'" class="faq">
        <h3>F.A.Q.s</h3>
        <h4>Is there a wedding registry ?</h4>
        <p class="mali">
          Your presence will be a present enough, but for those who
          wish to contribute, please be aware that a box for cards will be
          set out during the reception for donation towards our
          honeymoon fund.
        </p>
        <h4>What is the appropriate attire for this wedding?</h4>
        <p
          class="mali"
        >We want everybody to come to our wedding wearing what makes them feel happy and comfortable (however, clothing is mandatory!). In case it is helpful, our inspiration for decoration includes natural colors and rustic decorations. Keep in mind that the event is outdoors and the weather will probably be hot!</p>

        <h4>Will there be parking and walking accessibility at the venue?</h4>
        <p
          class="mali"
        >The venue isn’t easily accessible by foot but there is a big parking lot and there will be a shuttle service to and from the nearest hotel in Chehalis, WA — you will find more info in the invite.</p>

        <h4>What’s on the menu?</h4>
        <p
          class="mali"
        >The dinner is catered by Ranch House BBQ from Olympia, WA and we will be having smoked chicken and beef ribs along with mixed salads, coleslaw, and cornbread and different veggie dishes.</p>

        <h4>What is the Orchard at Sunshine Hill?</h4>
        <p
          class="mali"
        >The Orchard at Sunshine Hill is an outdoor venue on a Western Washington farm that was originally established in the mid-1800s. The historic farm-turned-venue is nestled atop a large hill in a 100+-year-old orchard and has tremendous views of Mt. Rainier, Mt. Adams, and Mt. St. Helens.</p>
      </ContactBox>

      <ContactBox v-else-if="invit == '2us'" class="faq">
        <h3>F.A.Q.s</h3>
        <h4>Is there a wedding registry?</h4>
        <p
          class="mali"
        >Your presence will be a present enough but for those who wish to contribute, please be aware that a box for cards will be set out during the reception for donations towards our honeymoon fund.</p>

        <h4>What is the appropriate attire for this wedding?</h4>
        <p
          class="mali"
        >We want everybody to come to our wedding wearing what makes them feel happy and comfortable (however, clothing is mandatory!). In case it is helpful, our inspiration for decoration includes natural colors and rustic decorations. Keep in mind that the events are mostly outdoors and the weather will probably be hot!</p>

        <h4>Will there be parking and walking accessibility at the venue?</h4>
        <p
          class="mali"
        >Both venues aren’t easily accessible on foot but have big parking lots. In the US, there will also be a shuttle service to and from the nearest hotel in Chehalis, WA — you will find more info in the invite. In France, you can spend the night at the venue itself. Please sign up for these services in your RSVP.</p>

        <h4>What’s on the menu?</h4>
        <p
          class="mali"
        >The dinner in the US is catered by the Ranch House BBQ from Olympia, WA and we will have smoked chicken and beef ribs along with mixed salads, coleslaw, and cornbread. In France, we will have a traditional meal from the Jura including yellow-wine chicken, morels, and various other veggie dishes.</p>

        <h4>What are the venues?</h4>
        <p
          class="mali"
        >The Orchard at Sunshine Hill is an outdoor venue on a Western Washington farm nestled in a 100+-year-old orchard. L’Ermitage des Frasses is located in the middle of nature at 1000m elevation. It was used for religious retreats in the 18th century and is now a great venue.</p>
      </ContactBox>

      <Leaves hpos="right" :scroll-v="scrollV" :ratio="0.7" :vpos="1" />
      <Leaves hpos="left" :scroll-v="scrollV" :ratio="1.3" :vpos="1.2" />

      <Leaves hpos="right" :scroll-v="scrollV" :ratio="0.7" :vpos="0" />
      <Leaves hpos="left" :scroll-v="scrollV" :ratio="1.3" :vpos="0.2" />

      <Leaves hpos="right" :scroll-v="scrollV" :ratio="0.6" :vpos="-1" />
      <Leaves hpos="left" :scroll-v="scrollV" :ratio="1.2" :vpos="-1.2" />

      <Leaves hpos="left" :scroll-v="scrollV" :ratio="0.5" :vpos="-0.3" />

      <GrahamSlideVue v-if="showGraham" />
      <ClemVue v-if="showClem" />
    </div>
  </div>
</template>
<script>
import ContentPage from "./components/ContentPage.vue"
import TitleHeader from "./components/TitleHeader.vue"
import Parents from "./components/Parents.vue"
import Program from "./components/Program.vue"
import Leaves from "./components/Leaves.vue"
import Venues from "./components/Venues.vue"
import ContactBox from "./components/ContactBox.vue"
import GrahamSlideVue from "./components/GrahamSlide.vue"
import ClemVue from "./components/Clem.vue"
import CTAButton from "./components/CTAButton.vue"
import PhotoCard from "./components/PhotoCard.vue"
export default {
  components: {
    TitleHeader,
    ContentPage,
    Parents,
    Program,
    Leaves,
    Venues,
    ContactBox,
    GrahamSlideVue,
    ClemVue,
    CTAButton,
    PhotoCard
  },
  data() {
    return {
      scrollV: 0,
      invit: '',
      showGraham: false,
      showClem: false,
      scrollVSum: 0
    }
  },
  watch: {
    'scrollV': 'onScrollV'
  },
  computed: {
    displayDate() {
      if (this.invit) {
        const dates = {
          '1fr': '06.08.2022',
          '2fr': '06.08.2022 - 21.08.2022',
          '1us': '08.21.2022',
          '2us': '08.06.2022 - 08.21.2022',
          'frp': '06.08.2022',
        }
        return dates[this.invit]
      }
      return ''
    },
    lang() {
      if (this.invit.endsWith('us')) {
        return 'us'
      }
      return 'fr'
    },
    formUrl() {
      const urlMap = {
        'frp': 'https://forms.gle/iyLhRDmgWY8NfhrS8',
        '1fr': 'https://forms.gle/obWqDvvGbq9KhyMX8',
        '1us': 'https://forms.gle/DPcYVDepH7Ntz8nD9',
        '2fr': 'https://forms.gle/4V9X4YePsguJCu4i9',
        '2us': 'https://forms.gle/SUfq2gC5SJN2t3zAA'
      }
      return urlMap[this.invit]
    }
  },
  created() {
    document.title = "Graham & Clémentine"
    console.log('v1.0.6')

    const avalaibleInvits = ['1fr', '2fr', '1us', '2us', 'frp']
    if (avalaibleInvits.includes(document.location.search.slice(1))) {
      this.invit = document.location.search.slice(1)
    }

    document.onscroll = (event) => this.handleScroll(event)
    document.addEventListener('keydown', this.handleKey);
  },
  methods: {
    handleScroll(event) {
      this.scrollV = event.target.documentElement.scrollTop / window.innerHeight
    },
    handleKey(keyevent) {
      if (keyevent.code == 'KeyG') {
        this.showGraham = !this.showGraham
      }
      if (keyevent.code == 'KeyC') {
        this.showClem = !this.showClem
      }
    },
    onScrollV(after, before) {
      const diff = Math.abs(after - before)
      this.scrollVSum += diff
      const coucou = [
        2.0, 7.0, 15.0, 20.0, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160
      ]
      coucou.forEach((v, i) => {
        if (this.scrollVSum > v && this.scrollVSum - diff < v) {
          this.showClem = i % 2 == 0
          this.showGraham = i % 2 == 1
        }
      })
    }
  }
}
</script>
<style>
@import url("static/css/reset.css");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Gwendolyn:wght@400;700&family=Mali&family=Cookie&display=swap");

.bg-container {
  background-color: white;
  background-image: url(static/img/2.jpg);
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -2;
}

body {
  width: 100vw;
  overflow-y: auto;
  overscroll-behavior: none;
}

.main {
  width: 100vw;
}

.big-fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 5;
}

.column {
  max-width: 800px;
  margin: 0px auto;
}

.text-block {
  flex: 1;
  text-align: center;
  margin: 1em;
}

h2 {
  font-family: "Gwendolyn", cursive;
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

h2.white {
  color: white;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 65%);
  margin: 2em 0em 1em;
}

h2.amatic {
  font-family: "Amatic SC";
  font-size: 3em;
  font-weight: normal;
  text-align: center;
  width: 100%;
}

h3 {
  font-family: "Gwendolyn", cursive;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  margin-left: 0.5em;
}

h3.white {
  color: white;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 65%);
}

h4 {
  font-family: "Gwendolyn", cursive;
  font-size: 3em;
  text-align: center;
  margin-left: 0.5em;
}

.one-page {
  height: 100vh;
}

.header-mask {
  background-image: url(static/img/1.jpg);
  background-size: cover;
  width: 100%;
  height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  clip-path: inset(0px 0px calc(100% - 7em) 0px);
}

.first-column {
  background-color: #40574d;
  background-image: url(static/img/1.jpg);
  background-size: cover;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.green-bg {
  background-color: #40574d;
  background-image: url(static/img/1.jpg);
  background-size: cover;

  max-width: 800px;
  height: 100vh;
  margin: 0px auto;
}

span.mali,
p.mali {
  font-family: "Mali", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
}

span.mali.small {
  font-size: 0.9em;
  color: rgb(0 0 0 / 69%);
}

p {
  text-align: left;
  margin: 1em;
}

span.bold {
  font-weight: bold;
}

strong {
  font-family: "Cookie", sans-serif;
  font-size: 2.2em;
  font-weight: bold;
  line-height: 1.5em;
}

@media (max-width: 1024px) {
  span.mali,
  p.mali {
    font-size: 1.5em;
  }
  p.mali,
  small {
    font-size: 1.2em;
  }

  strong {
    font-size: 4.4em;
    line-height: 1.5em;
  }
}

.color {
  color: #40574d;
}

.card {
  background: white;
  background-image: url(static/img/2.jpg);
  background-size: cover;
  border: solid 2px #000000cc;
  width: 600px;
  margin: 6em auto;
  padding: 2em 1em;
  text-align: center;
  transition: opacity 400ms;
  color: #000000cc;
}

a {
  color: currentColor;
}

img.img-fr {
  max-width: 40px;
  content: url(static/img/flag-france.png);
}

img.img-us {
  max-width: 40px;
  content: url(static/img/flag-usa.png);
}

.country-sep {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  align-items: flex-end;
  justify-content: center;
  margin: 2em auto;
}

div.cta-subtext {
  font-family: "Mali";
  font-size: 0.4em;
  text-align: center;
  margin-top: 0.5em;
}

.hand-animated {
  animation-name: hand-anim;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
}

@keyframes hand-anim {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-20px);
  }
}

.faq h3 {
  font-family: "Cookie";
  color: white;
  margin-bottom: 0.6em;
}

.faq h4 {
  font-family: "Cookie";
  color: white;
  text-align: left;
  font-size: 2.5em;
}
</style>
