<template>
    <div class="leave" :class="hpos" :style="`bottom: ${bottom}px`">

    </div>
</template>
<script>
export default {
    props:{
        scrollV: Number,
        hpos: String,
        vpos: Number,
        ratio: Number,
    },
    computed:{
        bottom(){
            const h = window.innerHeight
            return  (this.vpos + this.ratio * this.scrollV - 1) * h
        }
    }
}
</script>
<style scoped>
.leave{
    background-image: url(../static/img/leave.png);
    position: fixed;
    left: 0px;
    width: 200px;
    height: 200px;
    z-index: 6;
}
.leave.right{
    right: 0px;
    transform: scaleX(-1.0);
    left: auto;
}
</style>