<template>
    <div class="column">
        <div class="card" :style="cardPos">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        type:{
            type: String// wide, column
        },
        scrollV: {
            type: Number
        }
    },
    computed:{
        cardPos() {
            const pos = ''
            return pos
        }
    }
}</script>
<style scoped>

</style>