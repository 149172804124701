<template>
  <div class="program">
    <h2>{{ title }}</h2>
    <div class="path" :class="pathClass"></div>
    <div
      v-for="pos in countries"
      class="country"
      :key="pos.text + pos.top"
      :class="pos.o"
      :style="`top: ${pos.top}px; left: ${pos.left}px`"
    >
      <img :class="`img-${pos.flag}`" alt="flag" />
      <span class="text">{{ pos.text }}</span>
      <span class="hour">{{ pos.subtext }}</span>
    </div>

    <div
      v-for="pos in positions"
      class="pos"
      :key="pos.text + pos.top"
      :class="pos.o"
      :style="`top: ${pos.top}px; left: ${pos.left}px`"
    >
      <span class="hour">{{ pos.hour }}</span>
      <span class="text">{{ pos.text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    invit: String
  },
  data() {
    return {
      invits: {
        '1fr': {
          positions: [
            {
              hour: "15h30",
              text: "Cérémonie Laïque",
              top: 220,
              left: 383,

              o: 'bl'
            },
            {
              hour: "16h30",
              text: "Vin d’honneur",
              top: 326,
              left: 498,
              o: 'r'
            },
            {
              hour: "17h30",
              text: "Promenade & jeux d’extérieur",
              top: 400,
              left: 399,
              o: 'bl'
            },
            {
              hour: "19h00",
              text: "Dîner",
              top: 578,
              left: 350,
              o: 'b'
            },
            {
              hour: "21h30",
              text: "Soirée musicale",
              top: 664,
              left: 398,
              o: 'br'
            }
          ],
          pathClass: 'program3'
        },
        '2fr': {
          countries: [
            {
              flag: 'fr',
              text: 'En France',
              subtext: 'le 06.08.2022',
              o: 'r',
              top: 127,
              left: 245,

            },
            {
              flag: 'us',
              text: 'Aux Etats-Unis',
              subtext: 'le 21.08.2022',
              o: 'l',
              top: 112,
              left: 410,
            }
          ],
          positions: [
            {
              hour: "14h00",
              text: "Accueil",

              top: 202,
              left: 489,

            }, {
              hour: "15h00",
              text: "Cérémonie Laïque",
              top: 284,
              left: 490,
            }, {
              hour: "15h30 ",
              text: "Cérémonie Laïque",
              top: 224,
              left: 308,
              o: 'bl'
            }, {
              hour: "16h30",
              text: "Vin d’honneur",
              top: 337,
              left: 311,
              o: 'bl'
            }, {
              hour: "15h30 ",
              text: "Vin d’honneur",
              top: 359,
              left: 490,
            }, {
              hour: "16h30",
              text: "Dîner & discours",
              top: 437,
              left: 493,
            }, {
              hour: "17h30",
              text: "Promenade & jeux d’extérieur",
              top: 447,
              left: 314,
              o: 'bl'
            }, {
              hour: "18h00",
              text: "Danses & musique",
              top: 515,
              left: 490,
            }, {
              hour: "19h00",
              text: "Dîner",
              top: 564,
              left: 312,
              o: 'bl'
            }, {
              hour: "18h30",
              text: "Dessert",
              top: 590,
              left: 486,
            }, {
              hour: "19h00",
              text: "Fête",
              top: 671,
              left: 485,
            }, {
              hour: "21h30",
              text: "Soirée musicale",

              top: 703,
              left: 314,
              o: 'bl'

            }, {
              hour: "21h00",
              text: "Au revoir !",

              top: 745,
              left: 487,


            }

          ],
          pathClass: 'program2'
        },
        '2us': {
          countries: [
            {
              flag: 'fr',
              text: 'In France',
              subtext: '08.06.2022',
              o: 'r',
              top: 127,
              left: 245,

            },
            {
              flag: 'us',
              text: 'In the USA',
              subtext: '08.21.2022',
              o: 'l',
              top: 112,
              left: 410,
            }
          ],
          positions: [
            {
              hour: "2:00 PM",
              text: "Welcome",

              top: 202,
              left: 489,

            }, {
              hour: "3:00 PM",
              text: "Ceremony",
              top: 284,
              left: 490,
            }, {
              hour: "3:30 PM ",
              text: "Ceremony",
              top: 224,
              left: 308,
              o: 'bl'
            }, {
              hour: "4:30 PM",
              text: "Drinks",
              top: 337,
              left: 311,
              o: 'bl'
            }, {
              hour: "4:30 PM ",
              text: "Drinks & Photos",
              top: 359,
              left: 490,
            }, {
              hour: "4:30 PM",
              text: "Dinner & Toasts",
              top: 437,
              left: 493,
            }, {
              hour: "5:30 PM",
              text: "Outdoor activities",
              top: 447,
              left: 314,
              o: 'bl'
            }, {
              hour: "6:00 PM",
              text: "Dances & Music",
              top: 515,
              left: 490,
            }, {
              hour: "7:00 PM",
              text: "Dinner",
              top: 564,
              left: 312,
              o: 'bl'
            }, {
              hour: "6:30 PM",
              text: "Cake",
              top: 590,
              left: 486,
            }, {
              hour: "7:00 PM",
              text: "Party",
              top: 671,
              left: 485,
            }, {
              hour: "9:30 PM",
              text: "Party (All night long)",

              top: 703,
              left: 314,
              o: 'bl'

            }, {
              hour: "9:00 PM",
              text: "Farewell",

              top: 745,
              left: 487,

            }

          ],
          pathClass: 'program2'
        },
        '1us': {
          positions: [
            {
              hour: "2:00 PM",
              text: "Welcome",

              top: 193,
              left: 473,
              o: 'l'

            }, {
              hour: "3:00 PM",
              text: "Ceremony",
              top: 277,
              left: 496,
            }, {
              hour: "3:30 PM ",
              text: "Drinks & Photos",
              top: 350,
              left: 453, o: 'l'
            }, {
              hour: "4:30 PM",
              text: "Dinner & Toasts",
              top: 423,
              left: 402,

            }, {
              hour: "6:00 PM",
              text: "Dances & Music",
              top: 509,
              left: 406, o: 'bl'
            }, {
              hour: "6:30 PM",
              text: "Cake",
              top: 612,
              left: 416,
            }, {
              hour: "7:00 PM",
              text: "Party",
              top: 681,
              left: 324, o: 'bl'
            }, {
              hour: "9:00 PM",
              text: "Farewell",

              top: 765,
              left: 390,
            }

          ],
          pathClass: 'program1'
        }
      }

    }
  },
  computed: {
    positions() {
      return this.invits[this.invit].positions.map((x, i) => {
        x.id = `o${i}`
        return x
      })
    },
    countries() {
      return this.invits[this.invit].countries?.map((x, i) => {
        x.id = `o${i}`
        return x
      })
    },
    pathClass() {
      return this.invits[this.invit].pathClass
    },
    title() {
      let t = "Timeline"
      if (this.invit.indexOf('fr') != -1) {
        t = "Programme"
      }
      if (this.invit.startsWith('2')) {
        return t + 's'
      }
      return t
    }
  }
}
</script>
<style scoped>
.pos {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}
.pos .hour {
  font-family: Mali, sans-serif;
  color: #729d8d;
  font-weight: bold;
}

.pos .text {
  font-family: "Amatic SC", sans-serif;
  font-size: 2em;
  color: #000000cc;
}

.program {
  height: 850px;
  width: 800px;
  margin: 1em auto;
  border: solid 2px black;
  background-image: url(../static/img/2.jpg);
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.path.program2 {
  background-image: url(../static/img/program2.png);
  background-size: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  left: 230px;
  top: 110px;
}

.path.program3 {
  background-image: url(../static/img/program3.png);
  background-size: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  left: 230px;
  top: 110px;
}
.path.program1 {
  background-image: url(../static/img/program1.png);
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  left: 230px;
  top: 40px;
}

h2 {
  color: black;
  margin-top: 0.5em;
}

.pos.bl {
  transform: translate(calc(-100% - 20px));
  text-align: right;
}

.pos.b {
  text-align: center;
  transform: translate(calc(-50% - 10px), 20px);
}

.pos.l {
  transform: translate(calc(-100% - 24px), -15px);
  flex-direction: row-reverse;
  align-items: baseline;
}

.pos.l .hour {
  margin-left: 0.2em;
}

.country {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.country .text {
  font-family: "Cookie", cursive;
  color: rgba(0, 0, 0, 0.7);
  font-size: 2em;
  text-align: center;
}

.country .hour {
  font-family: "Mali", sans-serif;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.country.r img {
  position: absolute;
  left: -47px;
  top: 5px;
}
.country.l img {
  position: absolute;
  right: -47px;
  top: 5px;
}

@media (max-width: 1024px) {
  .pos .hour {
    font-family: Mali, sans-serif;
    font-size: 2em;
  }

  .pos .text {
    font-family: Mali, sans-serif;
    font-size: 2em;
  }

  .country .hour {
    font-size: 1.2em;
  }
}
</style>