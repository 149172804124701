<template>
  <div class="container">
    <div class="title" :style="grahamPos">Graham</div>
    <div class="title" :style="etPos">&</div>
    <div class="title" :style="clementinePos">Clémentine</div>
    <div class="date" :style="datePos">
      <slot></slot>
    </div>
    <ScrollIndicator :lang="lang" v-if="showScrollIndicator" />
  </div>
</template>
<script>
import ScrollIndicator from "./ScrollIndicator.vue"
export default {
  components: {
    ScrollIndicator
  },
  props: {
    scrollV: {
      type: Number
    },
    lang: String
  },
  data() {
    return {
      break: 0.3,
      indicatorTime: false
    }
  },
  computed: {
    grahamPos() {
      if (this.scrollV < this.break) {
        return "top: calc( 50% - 1em ); left: 50%;"
      }
      return "top: 1em; left: calc( 50% + 3em); font-size: 4em;"
    },
    etPos() {
      if (this.scrollV < this.break) {
        return "top: 50%; left: 50%"
      }
      return "top: 1em; left: 50%; font-size: 4em;"
    },
    clementinePos() {
      if (this.scrollV < this.break) {
        return "top: calc( 50% + 1em ); left: 50%;"
      }
      return "top: 1em; left: calc( 50% - 3em); font-size: 4em;"
    },
    datePos() {
      if (this.scrollV < this.break) {
        return "left: 50%; top: calc(50% + 6em);"
      }
      return "top: 1em; left: 50%; opacity: 0%;"
    },
    showScrollIndicator() {
      return this.scrollV < this.break
    }

  },
  mounted() {
  }
}
</script>
<style scoped>
div.title {
  font-family: "Gwendolyn", cursive;
  color: white;
  font-size: 6em;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 65%);
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all 400ms;
}

div.date {
  font-family: "Mali", sans-serif;
  color: rgb(255 255 255 / 90%);
  font-size: 2em;
  text-align: center;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 65%);
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all 400ms;
}

.container {
  width: 100vw;
  height: 100vh;
}
</style>