<template>
  <ContentPage>
    <div>
      <h2>Photos</h2>
      <template v-if="lang == 'fr'">
        <p class="mali center">Vous trouverez ici les photographies prises lors du mariage.</p>
        <p class="mali center">
          En attendant, nous avons hâte de vous retrouver
          pour partager cette belle fête avec vous !
        </p>
      </template>
      <template v-else>
        <p class="mali center">This is where you will find the pictures taken during the wedding.</p>
        <p
          class="mali center"
        >Until then, we are looking forward to celebrating our big day with you!</p>
      </template>
    </div>
    <div class="big-photo" />
  </ContentPage>
</template>
<script>
import ContentPage from "./ContentPage.vue"

export default {
  components: {
    ContentPage,
  },
  props: {
    lang: String
  }
}
</script>
<style>
.big-photo {
  background: transparent;
  background-image: url(../static/img/big_photo.jpg);
  width: 100%;
  height: 360px;
  background-size: cover;
  border-radius: 100%;
  padding: 20px;
  border: black solid 2px;
}
p.center {
  text-align: center;
}
</style>