<template>
    <div class="column">
        <div :class="`content-${size}`">
            <div class="place card" :class="size">
                <div v-for="(add,i) in address" :key="i">
                    <span class="mali" :class="{ 'strong': i == 0 }">{{ add }}</span>
                </div>
            </div>
            <Map :size="size" :location="country" />
        </div>
        <div class="travel-info">
            <span class="place-indicator mali" v-for="info in travelInfo" :key="info.text">
                <i :class="`fas ${info.icon}`"></i>
                {{ info.text }}
            </span>
        </div>
    </div>
</template>
<script>
import Map from "./Map.vue"
export default {
    components: {
        Map
    },
    props: {
        size: String,
        country: String,
        lang: String
    },
    computed: {
        address() {
            if (this.country == 'fr') {
                return [
                    "Gîte l’Ermitage des Frasses",
                    "6 Hameau les Frasses en Haut",
                    "39150 Château-des-Prés"
                ]
            }
            return [
                "The Orchard at Sunshine Hill",
                "2178 S Scheuber Rd,",
                "Chehalis, Washington 98532"
            ]
        },
        travelInfo() {
            if (this.country == 'fr') {
                if (this.lang == 'fr') {
                    return [
                        { icon: "fa-car", text: "à 1 heure de Lons-le-Saunier" },
                        { icon: "fa-car", text: "à 1 h 40 de Besançon" },
                        { icon: "fa-car", text: "à 2 h de Lyon" }
                    ]
                } else {
                    return [
                        { icon: "fa-car", text: "1 hour from Geneva" },
                        { icon: "fa-car", text: "5 hours from Paris" },
                        { icon: "fa-car", text: "2 hours from Lyon" },
                    ]
                }
            } else {
                if (this.lang == 'us') {
                    return [
                        { icon: "fa-car", text: "1 h 30 North of Portland, OR." },
                        { icon: "fa-car", text: "2 hours South of Seattle, WA." },
                    ]
                } else {
                    return [
                        { icon: "fa-car", text: "à 1 h 30 de Portland (Oregon)" },
                        { icon: "fa-car", text: "à 2 heures de Seattle (Washington)" },
                    ]
                }
            }
        }
    }
}
</script>
<style scoped>
span.strong {
    font-weight: bold;
    color: black;
}

.place.card {
    margin-bottom: 2em;
}

.travel-info {
    padding: 2em 0em;
    margin: auto;
    width: 600px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.place-indicator {
    color: white;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 89%);
}

.content-sm {
    display: flex;
    max-width: calc(800px - 2em);
    margin: auto;
}

.place.card.sm {
    height: 254px;
    margin: 0px 1em 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>