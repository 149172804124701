<template>
  <div class="bottom-position">
    <div class="scroll-text">
      <span class="mali">{{ scrollText }}</span>
    </div>
    <div class="arrow-anim">
      <svg width="200" height="50">
        <line x1="20" y1="10" x2="100" y2="30" />
        <line x1="180" y1="10" x2="100" y2="30" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lang: String
  },
  computed: {
    scrollText() {
      if (this.lang == 'fr') {
        return "Faire défiler"
      }
      return "Scroll down"
    }
  }

}
</script>
<style scoped>
.bottom-position {
  position: fixed;
  bottom: 20px;
  left: calc(50% - 100px);
}
.arrow-anim {
  animation-name: arrow;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  opacity: 0;
}
line {
  stroke-width: 2px;
  stroke: rgba(255, 255, 255, 0.9);
}

@keyframes arrow {
  0% {
    transform: translateY(0px);
    opacity: 0.1;
  }
  100% {
    transform: translateY(20px);
    opacity: 1;
  }
}

.scroll-text {
  color: white;
  width: 200px;
  text-align: center;
  font-size: 1.3em;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 65%);
}
</style>